import React, { useEffect, useState} from 'react';
import { useWatch } from 'react-hook-form';

import {
  List,
  Datagrid, 
  TextField, 
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  // useGetIdentity,
  DeleteButton,
  FileInput,
  FileField,
  useGetList
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import ToggleRowStyle from './Common';
import { useParams } from 'react-router-dom';
import { ContactSupportOutlined } from '@mui/icons-material';
// import { Label } from '@mui/icons-material';

function getWeekNumber(date) {
  const validDate = new Date(date);
  const firstJan = new Date(validDate.getFullYear(), 0, 1);
  const dayOfYear = ((validDate - firstJan + 86400000) / 86400000); // Days since January 1st
  return Math.ceil((dayOfYear - (validDate.getDay() || 7) + 1) / 7);
}

function getWeeksBetween(startDate, endDate) {
  const startWeek = getWeekNumber(startDate);
  const endWeek = getWeekNumber(endDate);
  const weeks = [];

  for (let week = startWeek; week <= endWeek; week++) {
      weeks.push(week);
  }

  return weeks;
}

function getWeeksPerTerm(terms) {
  const weeksPerTerm = {}
  for (let term of terms) {
      weeksPerTerm[term.id] = getWeeksBetween(term.from_date, term.to_date);
  }
  return weeksPerTerm;
}

const getWeeksChoices = (items) => items.map((item) => ({ id: item, name: `Week ${item}`}));

const WeekInput = (props) => {
  const { weeksPerTerm } = props;
  // console.log(`weeksPerTerm: ${JSON.stringify(weeksPerTerm)}`);
  const term_id = useWatch({ name: "term_id"});
  return <SelectInput choices={term_id ? getWeeksChoices(weeksPerTerm[term_id]) : []} source="week" label="Week" />
}

const LessonEdit = (props) => {
  const [weeksPerTerm, setWeeksPerTerm] = useState(null);
  const { data: terms } = useGetList('terms');
  useEffect(() => {        
      if (terms) setWeeksPerTerm(getWeeksPerTerm(terms));
  },[setWeeksPerTerm, terms]);
  const { id } = useParams();
  // const { data: identity, isLoading: identityLoading, error: identityError } = useGetIdentity();
  // if (identityLoading) return <>Loading...</>;
  // if (identityError) return <>Error...</>  
  const grades = [
    {id: 1, name: 'Grade 1'},
    {id: 2, name: 'Grade 2'},
    {id: 3, name: 'Grade 3'},
    {id: 4, name: 'Grade 4'},
    {id: 5, name: 'Grade 5'},
    {id: 6, name: 'Grade 6'},
    {id: 7, name: 'Grade 7'},
  ];  
  return weeksPerTerm && (
    <>
      <Edit {...props} title="Edit lesson">
        <SimpleForm>
          {/* <TextInput source="id" />  */}
          <ReferenceInput label="Term" source="term_id" reference="terms">
            <SelectInput />
          </ReferenceInput>
          <WeekInput weeksPerTerm={weeksPerTerm} />
          <SelectInput source="grade" choices={grades} />
          <ReferenceInput label="Subject" source="subject_id" reference="subjects">
            <SelectInput />
          </ReferenceInput>
          <TextInput source="title" />
          <RichTextInput source="description" />
          <FileInput label="New attachments" source="attachments" multiple={true} sx={{ boxShadow: '0px 0px 6px 4px darkgrey', padding: '0px 8px', '& span': { fontSize: '1.75em', color: 'darkred'}}}>
            <FileField source="src" title="title" />
          </FileInput>       
        </SimpleForm>
      </Edit>
      <h2 style={{ backgroundColor: '#cdcdcd', paddingLeft: '8px'}}>Current attachments</h2>
      <SimpleForm toolbar={false}>
        <List {...props}
          actions={false}
          // empty={<MyEmpty lesson_id={id} />}
          resource="lesson_items"     
          title=" "
          filter={{ lesson_id: id }}
          sort={{ field: 'id', order: 'ASC' }}
          sx={{ 
            display: 'flex', 
            flexDirection: 'row', 
            minWidth: '100%',
            '& .RaDatagrid-table tr td:last-child': {
              maxWidth: '10%',
              width: '10%',
              // border: '1px solid red',
            },
          }}
        >
          <Datagrid 
            key={record => `${record.id}`} 
            rowStyle={ToggleRowStyle} 
            bulkActionButtons={false}
          >
            <TextField source='file' />     
            <DeleteButton mutationMode="pessimistic" redirect={`/lessons/${id}`} />                       
          </Datagrid>
        </List>
      </SimpleForm>
    </>
  );
}

export default LessonEdit;

