//import { Image } from '@mui/icons-material';
// import React from 'react';
import React, 
  { 
    useState, 
    useEffect 
} from 'react';
import Marquee from "react-fast-marquee";

async function makeAPICall() {
  return [
    {name: 'Carpointe', file: 'carpointe.png'},
    {name: 'Fat Fish', file: 'fatfish.png'},
    {name: 'Herholdts', file: 'herholdts.png'},
    {name: 'Medihelp', file: 'medihelp.png'},
    {name: 'M Sec security', file: 'msec.png'},
    {name: 'Strauss Attorneys', file: 'strauss.png'},
    {name: 'Tavcor JAC', file: 'tavcor.png'},
    {name: "Engen York", file: 'york.png'},
    {name: "Anine van der Westhuizen", file: 'anine.png'},
  ]
}

const GetSponsorsFromAPI = () => {
    const [sponsors, setSponsors] = useState("");
    useEffect(() => {
        async function fetchData() {
            const sponsorsFromAPI = await makeAPICall();
            setSponsors(sponsorsFromAPI);
        }
        fetchData();
    }, []);

    return sponsors 
        ? (<p style={{whiteSpace: 'nowrap'}}>{sponsors.map(renderSponsors)}</p>)
        : (<p style={{visibility: 'hidden'}}>Loading</p>);
};

const renderSponsors = (item, index) => {
    return (
        <img key={index} style={{padding: '0px 20px'}} height={80} alt={item.name} src={`/logos/${item.file}`} />
    );
}

const StaticSponsors = () => {
  return (
    <GetSponsorsFromAPI />
  );
}

const MarqueeSponsors = () => {
  return (
    <Marquee>
      <GetSponsorsFromAPI/>
    </Marquee>
  )
}

export { StaticSponsors, MarqueeSponsors };


