import React, { useState, useEffect } from 'react';
import { instanceUrl } from './supabase';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Loading } from 'react-admin';

/**
 * A component that renders a page for resetting the current user password through Supabase.
 * @param props
 * @param props.children The content of the page. If not set, it will render a ForgotPasswordForm.
 *
 * @example
 * import { ForgotPasswordPage } from 'ra-supabase-ui-materialui';
 * import { Admin, CustomRoutes } from 'react-admin';
 *
 * const App = () => (
 *    <Admin dataProvider={dataProvider}>
 *      <CustomRoutes>
 *       <Route path={ForgotPasswordPage.path} element={<ForgotPasswordPage />} />
 *     </CustomRoutes>
 *      ...
 *  </Admin>
 * );
 */
export const MyVerifyAccountPage = () => {
    const [searchParams] = useSearchParams();  
    const newQueryString = searchParams.toString();
    console.log(`newQueryString: ${newQueryString}`);
    const newURI = `${instanceUrl}/auth/v1/verify?${newQueryString}`;    
    window.location.href = newURI;    
};

MyVerifyAccountPage.path = '/verify-account';